import {
  ChitChatCard,
  MessageTabData,
  MessageTabs,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { MessageContent } from '../MessageContent';
import { useEffect, useMemo } from 'react';
import { prepareExternalModelsTabContent } from 'src/utils';
import { TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { renderMessageTabList } from '../../renderMessageTabList';
import { NINJA_AGENT_TITLE } from 'src/constants';
import { useIsNinjaModel } from 'src/hooks';

interface ChatCardProps {
  message: SharedMessageType;
  onTabClick: (tabName: string) => void;
}

export const ChatCard = ({ message, onTabClick }: ChatCardProps) => {
  const { isNinjaModel } = useIsNinjaModel({ message });

  const { data } = message.payload as ChitChatCard;

  const referenced_model_summary = data?.referenced_model_summary || {};
  const { references } = referenced_model_summary || {};
  const ninja_model_output = data?.ninja_model_output || '';

  const tabContent = useMemo(
    () =>
      references
        ? prepareExternalModelsTabContent({ references, isNinjaModel })
        : [],
    [references, isNinjaModel],
  );

  const tabsData: MessageTabData[] = useMemo(() => {
    const filteredTabContent = tabContent.filter(
      ({ tabName }) => tabName !== MessageTabs.SUPER_AGENT,
    );

    return [
      ...(ninja_model_output
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName: 'ninjaOutlined',
              tabTitle: NINJA_AGENT_TITLE,
              content: ninja_model_output || '',
            },
          ]
        : []),
      ...filteredTabContent,
    ];
  }, [ninja_model_output, tabContent]);

  const defaultTab = tabsData.length
    ? tabsData[0].tabName
    : MessageTabs.NINJA_AGENT;

  useEffect(() => {
    onTabClick(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  return (
    <Tabs<string> defaultActiveTab={defaultTab} onTabClick={onTabClick}>
      {renderMessageTabList({ data: tabsData, isNinjaModel })}

      {tabsData.map(({ tabName, content, references }) => (
        <TabPanel key={tabName} tab={tabName}>
          <MessageContent
            content={content}
            references={references}
            messageSkill={data?.skill}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};
