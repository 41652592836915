import {
  isString,
  isObject,
  ExternalModelReference,
  ExternalModelTabContent,
  MessageTabs,
  SearchWebPageReference,
  SharedMessage,
  isResearchTaskCreationCard,
  isChitChatCard,
} from 'src/types';
import { uppercaseFirstLetter } from './general';
import { AiGenerationData } from 'src/types/shared/models/AiGenerationData';
import {
  externalModelsSet,
  imageGeneratorsModelsSet,
  newExternalModelsList,
  NINJA_AGENT_TITLE,
} from 'src/constants';
import type { ImageGenData } from 'src/types/models/ImageGenData';
import { ExternalModelsSettings } from 'src/types/models/ExternalModelsSettings';
import {
  getDefaultModelIconName,
  getTextModelDisplayName,
} from './externalModels';

/**
 * getReadableKey() returns readable key.
 * @param key string
 * @returns string
 */
export function getReadableKey(key: string): string {
  return key
    .split('_')
    .map((word) => uppercaseFirstLetter(word))
    .join(' ');
}

/**
 * flattenObject() converts message to a readable format.
 * @param obj Record
 * @param indent number
 * @returns string
 */
export const OMIT_MESSAGE_KEYS = [
  'data',
  'metadata_info',
  'actions',
  'search_result',
  'summary',
  'snippet',
  'generative_ai_result',
  'content',
  'code_snippets',
  'raw_text',
  'references',
  'web_page_references',
  'combined_references',
  'key',
];

export const IGNORE_MESSAGE_KEYS = [
  'query',
  'payload_type',
  'width',
  'height',
  'progress',
  'version',
  'model',
  'words_written_count',
  'words_read_count',
  'domain',
  'source',
  'favicon',
  'title',
  'order',
];

export function flattenObject(
  obj: Record<string, unknown>,
  indent = 0,
): string {
  let result = '';

  for (const key in obj) {
    if (!IGNORE_MESSAGE_KEYS.includes(key)) {
      const readableValue = isString(obj[key])
        ? uppercaseFirstLetter(obj[key] as string)
        : obj[key];
      const readableKey = OMIT_MESSAGE_KEYS.includes(key)
        ? ''
        : `${''.repeat(indent)}${getReadableKey(key)}: \n`;

      if (Array.isArray(readableValue)) {
        for (const [index, item] of readableValue.entries()) {
          if (isObject(item)) {
            if (!OMIT_MESSAGE_KEYS.includes(key)) {
              result += `${''.repeat(indent)}${key}[${index + 1}]:\n`;
            }
            result += `${flattenObject(
              item as Record<string, unknown>,
              indent + 1,
            )}`;
          } else {
            result += `${''.repeat(indent)}${key}[${index + 1}]: ${item}\n`;
          }
        }
      } else if (isObject(readableValue)) {
        result += `${readableKey}${flattenObject(
          readableValue as Record<string, unknown>,
          indent + 2,
        )}`;
      } else {
        result += `${''.repeat(indent)}${readableKey} ${readableValue}\n\n`;
      }
    }
  }

  return result;
}

export const prepareExternalModelsReferences = (
  references: Record<string, AiGenerationData>,
): ExternalModelReference[] => {
  return (
    Object.entries(references)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, { model }]) => model && externalModelsSet[model])
      .map(([key, { content, model }]) => {
        return {
          modelKey: key,
          content: content || '',
          icon: model ? externalModelsSet[model].icon_name : null,
          title: model ? externalModelsSet[model].display_name : '',
          url: model || '',
        };
      })
  );
};

export const prepareGeneratedImages = (
  images: Record<string, ImageGenData>,
) => {
  return Object.entries(images).map(([key, image]) => {
    return {
      id: key,
      url: image?.image_url || '',
      icon:
        image.model && imageGeneratorsModelsSet[image.model]
          ? imageGeneratorsModelsSet[image.model]?.icon_name
          : null,
      title:
        imageGeneratorsModelsSet[image?.model || '']?.display_name ||
        image?.model ||
        '',
      height: 200,
      width: 200,
      source: image?.model || '',
      status: image?.status || '',
    };
  });
};

export const prepareExternalModelsTabContent = ({
  references,
  externalModels,
  isNinjaModel,
}: {
  references: Record<string, AiGenerationData>;
  externalModels?: ExternalModelsSettings;
  isNinjaModel?: boolean;
}): ExternalModelTabContent[] => {
  return Object.values(references)
    .filter(({ model }) => model)
    .map(({ content, model, status, reasoning }) => {
      const ninjaTabIcon = isNinjaModel ? 'ninjaOutlined' : '';
      const ninjaTabTitle = isNinjaModel ? NINJA_AGENT_TITLE : '';

      return {
        content: content || '',
        iconName:
          ninjaTabIcon ||
          (model
            ? newExternalModelsList[model]?.icon_name ||
              externalModelsSet[model]?.icon_name ||
              getDefaultModelIconName(model) ||
              undefined
            : undefined),
        tabTitle:
          ninjaTabTitle ||
          (model &&
          (newExternalModelsList[model]?.display_name ||
            externalModelsSet[model]?.display_name)
            ? newExternalModelsList[model]?.display_name ||
              externalModelsSet[model]?.display_name
            : externalModels
            ? getTextModelDisplayName(externalModels, model || '') || ''
            : ''),
        tabName: isNinjaModel ? MessageTabs.NINJA_AGENT : model || '',
        status: status || undefined,
        reasoning: reasoning || undefined,
      };
    });
};

export const getTextContentFromMessage = ({
  message,
  activeTab,
}: {
  message?: SharedMessage;
  activeTab: string;
}) => {
  if (!message) {
    return '';
  }

  const { payload } = message;

  if (isResearchTaskCreationCard(payload)) {
    if (activeTab === MessageTabs.NINJA_AGENT) {
      return (
        payload.research_card?.data?.search_result?.content?.content ||
        payload.research_card?.data?.search_result?.summary ||
        ''
      );
    }

    if (activeTab.includes(MessageTabs.SUPER_AGENT)) {
      return (
        payload.research_card?.data?.deep_research_result?.content?.content ||
        payload.research_card?.data?.deep_research_result?.summary ||
        ''
      );
    }

    return (
      Object.values(
        payload.research_card?.data?.generative_ai_result
          ?.referenced_model_summary?.references || {},
      ).find((item) => item.model === activeTab)?.content || ''
    );
  }

  if (isChitChatCard(payload)) {
    if (activeTab === MessageTabs.NINJA_AGENT) {
      return (
        payload.data?.ninja_model_output ||
        Object.values(
          payload.data?.referenced_model_summary?.references || {},
        )[0]?.content ||
        ''
      );
    }

    return (
      Object.values(
        payload.data?.referenced_model_summary?.references || {},
      ).find((item) => item.model === activeTab)?.content || ''
    );
  }

  return '';
};

export const getWebPageReferencesFromMessage = ({
  message,
  activeTab,
}: {
  message?: SharedMessage;
  activeTab: string;
}): SearchWebPageReference[] | undefined | null => {
  if (!message) {
    return;
  }

  const { payload } = message;

  if (!isResearchTaskCreationCard(payload)) {
    return;
  }

  if (activeTab === MessageTabs.NINJA_AGENT) {
    return payload.research_card?.data?.search_result?.web_page_references;
  }

  if (activeTab.includes(MessageTabs.SUPER_AGENT)) {
    return payload.research_card?.data?.deep_research_result
      ?.web_page_references;
  }
};

export const getStringUrlListFromReferences = (
  references: SearchWebPageReference[],
) => {
  if (references.length === 0) {
    return '';
  }

  const urlList = references
    .map(({ url }, index) => `[${index + 1}]: ${url}`)
    .join('\n');

  return `\n\nReferences:\n${urlList}`;
};
