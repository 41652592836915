import { useEffect, useMemo } from 'react';
import {
  SharedMessage as SharedMessageType,
  ResearchCardData,
  ResearchTaskCreationCard,
} from 'src/types';
import { prepareExternalModelsTabContent } from 'src/utils';
import { ResearchMedia } from './components/ResearchMedia';
import { ThreadResearchReferences } from 'src/components/ThreadResearchReferences/ThreadResearchReferences';
import { TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { MessageTabData, MessageTabs } from 'src/types/message';
import { renderMessageTabList } from '../../renderMessageTabList';
import { MessageContent } from '../MessageContent';
import { newExternalModelsList, NINJA_AGENT_TITLE } from 'src/constants';
import { useIsNinjaModel } from 'src/hooks';

type ResearcherCardProps = {
  message: SharedMessageType;
  onTabClick: (tabName: string) => void;
};

export const ResearcherCard = ({
  message,
  onTabClick,
}: ResearcherCardProps) => {
  const { isNinjaModel } = useIsNinjaModel({ message, isResearcher: true });

  const research_card = (message.payload as ResearchTaskCreationCard)
    ?.research_card;

  const { search_result, deep_research_result, generative_ai_result } =
    (research_card?.data as ResearchCardData) || {};

  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};

  const {
    web_page_references = [],
    image_references,
    video_references,
  } = search_result || {};

  const ninjaTabIcon = isNinjaModel ? 'ninjaOutlined' : '';
  const ninjaTabTitle = isNinjaModel ? NINJA_AGENT_TITLE : '';

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName:
                ninjaTabIcon ||
                (search_result?.model
                  ? newExternalModelsList[search_result?.model]?.icon_name
                  : 'ninjaOutlined'),
              tabTitle:
                ninjaTabTitle ||
                (search_result?.model
                  ? newExternalModelsList[search_result?.model]?.display_name
                  : NINJA_AGENT_TITLE),
              content:
                search_result?.content?.content || search_result?.summary || '',
              references: web_page_references,
            },
          ]
        : []),
      ...(deep_research_result
        ? [
            {
              tabName: MessageTabs.SUPER_AGENT,
              iconName: ninjaTabIcon || 'ninjaPixProLogo',
              tabTitle:
                ninjaTabTitle ||
                (deep_research_result?.model
                  ? newExternalModelsList[deep_research_result?.model]
                      ?.display_name
                  : 'Ninja SuperAgent'),
              tabTitleClassName: isNinjaModel
                ? undefined
                : 'nj-message--highlighted-text',
              content:
                deep_research_result?.content?.content ||
                deep_research_result?.summary ||
                '',
              references: deep_research_result?.web_page_references,
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent({
            references: external_models_result,
            isNinjaModel,
          }).map(({ tabName, tabTitle, iconName, content }) => ({
            tabName,
            iconName,
            tabTitle,
            content,
          }))
        : []),
    ],
    [
      search_result,
      ninjaTabIcon,
      ninjaTabTitle,
      web_page_references,
      deep_research_result,
      isNinjaModel,
      external_models_result,
    ],
  );

  const defaultTab = tabsData.length
    ? tabsData[0].tabName
    : MessageTabs.NINJA_AGENT;

  useEffect(() => {
    onTabClick(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  return (
    <Tabs<string> defaultActiveTab={defaultTab} onTabClick={onTabClick}>
      {renderMessageTabList({ data: tabsData, isNinjaModel })}

      {tabsData.map(({ tabName, content, references }) => (
        <TabPanel key={tabName} tab={tabName}>
          <div className="nj-thread-research-card">
            <MessageContent content={content} references={references} />

            {references && (
              <ThreadResearchReferences researchReferences={references} />
            )}

            {tabName === MessageTabs.NINJA_AGENT && (
              <ResearchMedia
                images={image_references || []}
                videos={video_references || []}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};
