import { IconName } from 'src/components/Icon';
import { ExternalModelsSettings } from 'src/types/models/ExternalModelsSettings';

export const getDefaultModelIconName = (modelId: string): IconName | null => {
  const provider = modelId.split('/')[0];

  switch (provider) {
    case 'bedrock':
      if (modelId.includes('nova-')) {
        return 'nova';
      }
      return 'awsLogo';

    case 'openai':
      return 'chatGPTLogo';

    case 'gemini':
      return 'googleGeminiProLogo';

    case 'ninja':
      return 'ninjaOutlined';

    default:
      return null;
  }
};

export const getTextModelDisplayName = (
  settings: ExternalModelsSettings,
  targetModel: string,
): string | undefined => {
  return Object.values(settings)
    .flatMap((value) => (Array.isArray(value) ? value : []))
    .find((option) => option?.model_name === targetModel)?.display_name;
};
