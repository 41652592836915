import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { CITATION_REGEX } from 'src/constants';
import { MessageType, SharedMessage as SharedMessageType } from 'src/types';
import {
  getStringUrlListFromReferences,
  getTextContentFromMessage,
  getWebPageReferencesFromMessage,
} from 'src/utils';

type CopyMessageButtonProps = {
  message: SharedMessageType;
  disabled?: boolean;
  activeTab: string;
};

const SVG_SIZE = 20;

export const CopyMessageButton = ({
  message,
  disabled,
  activeTab,
}: CopyMessageButtonProps) => {
  const getContent = (message: SharedMessageType) => {
    switch (message.message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
      case MessageType.CODE_TASK_CREATION_CARD:
      case MessageType.CHAT_CARD:
        return getTextContentFromMessage({ message, activeTab });

      default:
        return message.content || '';
    }
  };

  const handleCopyClick = () => {
    if (!message) {
      return;
    }

    const content = getContent(message);
    const updatedContent = content.replace(
      CITATION_REGEX,
      (_, number) => `[${number}]`,
    );

    const references = getWebPageReferencesFromMessage({
      message,
      activeTab,
    });

    const copiedContent = references
      ? `${updatedContent}${getStringUrlListFromReferences(references)}`
      : updatedContent;

    navigator.clipboard.writeText(copiedContent);

    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Copied</span>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'nj-copy-snackbar',
        autoClose: 1000,
        closeButton: false,
        hideProgressBar: true,
      },
    );
  };

  return (
    <button
      data-e2e="copy-message-button"
      onClick={handleCopyClick}
      disabled={disabled}
    >
      <Copy size={SVG_SIZE} color="currentColor" />
    </button>
  );
};
