import { Icon as PhosphorIconType } from '@phosphor-icons/react';
import { SearchWebPageReference } from './api';
import { IconName } from 'src/components/Icon';
import { AiGenerationStatus } from './models/AiGenerationStatus';
import { CotReasoning } from './models/CotReasoning';

export enum MessageTabs {
  NINJA_AGENT = 'alias/ninjatech/ninja-agent',
  SUPER_AGENT = 'alias/ninjatech/super-agent',
}

export interface ExternalModelTabContent {
  tabTitle: string;
  iconName?: IconName;
  content: string;
  tabName: string;
  status?: AiGenerationStatus;
  reasoning?: CotReasoning;
}

export interface MessageTabData extends ExternalModelTabContent {
  references?: SearchWebPageReference[];
  IconComponent?: PhosphorIconType;
}

export type MediaType = 'image' | 'video';
