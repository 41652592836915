/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ResearchWebSearchEngine {
  GOOGLE = 'google',
  BING = 'bing',
  FASTEST = 'fastest',
}
