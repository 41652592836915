/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ResearchLlmAnthropicModel {
  CLAUDE_3_HAIKU_20240307 = 'claude-3-haiku-20240307',
  CLAUDE_3_SONNET_20240229 = 'claude-3-sonnet-20240229',
  CLAUDE_3_OPUS_20240229 = 'claude-3-opus-20240229',
}
