import cn from 'classnames';
import { TabList, Tab } from 'src/v2/commonComponents/Tabs';
import { Icon } from 'src/components/Icon';
import { SUPER_AGENT_AS_EXTERNAL_MODEL, SVG_SIZE_M } from 'src/constants';
import { MessageTabData, MessageTabs } from 'src/types';
import styles from './SharedMessage.module.scss';

interface MessageTabListProps {
  data: MessageTabData[];
  isNinjaModel?: boolean;
}

export const renderMessageTabList = ({
  data,
  isNinjaModel,
}: MessageTabListProps) => {
  return (
    <TabList>
      {data.map(({ tabName, iconName, IconComponent, tabTitle }) => {
        const isSuperAgentTab =
          tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
          tabName === MessageTabs.SUPER_AGENT;

        return (
          <Tab
            key={tabName}
            tab={tabName}
            className={cn(styles.tab, {
              [styles.highlighted]: isNinjaModel ? false : isSuperAgentTab,
            })}
          >
            <span className={cn(styles.icon)}>
              {iconName ? (
                <Icon type={iconName} size={SVG_SIZE_M} />
              ) : IconComponent ? (
                <IconComponent size={SVG_SIZE_M} />
              ) : null}
            </span>

            <span>{tabTitle}</span>
          </Tab>
        );
      })}
    </TabList>
  );
};
