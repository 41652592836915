import { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  SharedMessage as SharedMessageType,
  AvatarVideoPresetName,
} from 'src/types';
import { PlayerWrapper } from './components/PlayerWrapper';
import { useBreakpoint } from 'src/hooks';

interface SharedVideoProps {
  video: SharedMessageType;
}

export const SharedVideo = ({ video }: SharedVideoProps) => {
  const { isMobile } = useBreakpoint();

  const [inProgress, setInProgress] = useState<boolean>(false);

  const { video_title = '', video_resolution = '' } = video;

  const videoHeight = useMemo(() => {
    const screenWidth = window.innerWidth;

    if (
      video_resolution === AvatarVideoPresetName._4K_RAW ||
      video_resolution === AvatarVideoPresetName.HD
    ) {
      return isMobile ? Math.round((screenWidth * 9) / 16) : 405;
    }

    if (video_resolution === AvatarVideoPresetName.HD_VERTICAL) {
      return isMobile ? 568 : 720;
    }

    if (video_resolution === AvatarVideoPresetName.SQUARE) {
      return isMobile ? screenWidth : 600;
    }

    return 405;
  }, [isMobile, video_resolution]);

  const videoWidth = useMemo(() => {
    if (
      video_resolution === AvatarVideoPresetName._4K_RAW ||
      video_resolution === AvatarVideoPresetName.HD
    ) {
      return isMobile ? '100%' : 720;
    }

    if (video_resolution === AvatarVideoPresetName.HD_VERTICAL) {
      return isMobile ? 320 : 405;
    }

    if (video_resolution === AvatarVideoPresetName.SQUARE) {
      return isMobile ? '100%' : 600;
    }

    return 720;
  }, [video_resolution, isMobile]);

  return (
    <div className="nj-video">
      <PlayerWrapper inProgress={inProgress} videoTitle={video_title || ''}>
        <ReactPlayer
          url={video.content || ''}
          controls
          onPlay={() => setInProgress(true)}
          onPause={() => setInProgress(false)}
          onEnded={() => setInProgress(false)}
          height={videoHeight}
          width={videoWidth}
        />
      </PlayerWrapper>
    </div>
  );
};
