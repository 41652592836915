import { useMemo } from 'react';
import {
  NINJA_AGENT_AS_EXTERNAL_MODEL,
  NINJA_AGENT_TEXT_AUTO_MODEL,
  SUPER_AGENT_AS_EXTERNAL_MODEL,
} from 'src/constants';
import { type SharedMessage } from 'src/types';
import { SuperAgentModels } from 'src/types/models/SuperAgentModels';

export const useIsNinjaModel = ({
  message,
  isResearcher = false,
}: {
  message: SharedMessage;
  isResearcher?: boolean;
}) => {
  const superAgentModelIds = [
    `${SUPER_AGENT_AS_EXTERNAL_MODEL}:${SuperAgentModels.TURBO}`,
    `${SUPER_AGENT_AS_EXTERNAL_MODEL}:${SuperAgentModels.APEX}`,
    `${SUPER_AGENT_AS_EXTERNAL_MODEL}:${SuperAgentModels.REASONING}`,
  ];

  const ninjaModelIds = [
    NINJA_AGENT_TEXT_AUTO_MODEL,
    NINJA_AGENT_AS_EXTERNAL_MODEL,
    ...(isResearcher ? superAgentModelIds : []),
  ];

  const isNinjaModel = message.model_ids?.some((id) =>
    ninjaModelIds.includes(id),
  );

  return useMemo(
    () => ({
      isNinjaModel,
    }),
    [isNinjaModel],
  );
};
