import { MaskSad, SmileySad } from '@phosphor-icons/react';
import { SVG_SIZE_XXXL } from 'src/constants';
import styles from './MediaModalError.module.scss';
import { uppercaseFirstLetter } from 'src/utils';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';
import { MediaType } from 'src/types';

interface MediaModalErrorProps {
  status?: AiGenerationStatus | null;
  mediaType: MediaType;
}

export const MediaModalError = ({
  status,
  mediaType,
}: MediaModalErrorProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.errorContainer}>
        {status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ? (
          <>
            <MaskSad size={SVG_SIZE_XXXL} />
            <span>
              {uppercaseFirstLetter(mediaType)} violates content policies{' '}
            </span>
          </>
        ) : (
          <>
            <SmileySad size={SVG_SIZE_XXXL} />
            <span>Unable to generate {mediaType}</span>
          </>
        )}
      </div>
    </div>
  );
};
